import React from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import { useStaticQuery, graphql } from 'gatsby';

import {
  CONTACT_US,
  SUBSCRIPTION_ROUTE,
  HOME_LOGGED_IN_ROUTE,
  CREATE_ROUTE,
} from 'constants/navigation';
import {
  SUBSCRIPTION_NAV,
  HOME_NAV,
  EMAIL_NAV,
  ADD_NAV,
} from 'constants/font-awesome';

import useCanAddPass from 'hooks/use-can-add-pass';

import { Typography } from '@material-ui/core';

import useStyles from './styles';

const Page = loadable(() => import('components/page'));
const LazySubscriptionBanner = loadable(() => import('components/banners/in-app-banners/subscription'));
const LazySubscriptionView = loadable(() => import('views/subscription'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const SuspendedSubscriptionPage = ({ pricingModels }) => {
  const styles = useStyles();
  const { canAdd } = useCanAddPass();

  const { cmsJson: { pages: { subscription } } } = useStaticQuery(
    graphql`
      query  { cmsJson { pages { subscription { title } } } }`,
  );

  const { title } = subscription;

  return (
      <Page
          title="Subscriptions"
          description="Get the right subcription for your comapny or group">
          <LazySubscriptionBanner/>
          <Typography variant="h2" component="h1" className={styles.indexheading} align="center">
            {title}
          </Typography>
          <LazySubscriptionView pricingModels={pricingModels}/>
          <LazyOverrideFooter
            navItems={canAdd ? [
              { route: HOME_LOGGED_IN_ROUTE, icon: HOME_NAV, message: 'Home' },
              { route: CREATE_ROUTE, icon: ADD_NAV, message: 'Add' },
              { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' },
              { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
            ] : [
              { route: HOME_LOGGED_IN_ROUTE, icon: HOME_NAV, message: 'Home' },
              { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' },
              { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
            ]}
            currentItem="Subscription"
            />
      </Page>
  );
};

SuspendedSubscriptionPage.propTypes = { pricingModels: object };

export default SuspendedSubscriptionPage;
